import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [4],
		"/(public)/about": [~5],
		"/(public)/about/privacy-policy": [6],
		"/(public)/about/terms-of-service": [7],
		"/(public)/analyze-css": [8],
		"/(public)/ast-explorer": [~9],
		"/(public)/blog": [~10,[2]],
		"/(public)/blog/[slug]": [~11,[2]],
		"/(public)/css-code-quality": [~12],
		"/(public)/css-coverage": [13],
		"/(public)/css-diff": [14],
		"/(public)/css-layers-visualizer": [15],
		"/(public)/css-units-game": [16],
		"/(public)/custom-property-inspector": [17],
		"/(public)/design-tokens": [18],
		"/(public)/docs": [~19,[3]],
		"/(public)/docs/metrics": [~20,[3]],
		"/(public)/docs/metrics/[slug]": [~21,[3]],
		"/(public)/docs/recipes": [~22,[3]],
		"/(public)/docs/recipes/[slug]": [~23,[3]],
		"/(public)/funding": [~24],
		"/(public)/get-css": [25],
		"/(public)/hey-team-tailwind": [26],
		"/(public)/minify-css": [27],
		"/(public)/oss": [28],
		"/(public)/prettify-css": [29],
		"/(public)/projects-coming-soon": [30],
		"/(public)/shop": [31],
		"/(public)/shop/[product]": [~32],
		"/(public)/specificity-calculator": [33],
		"/(public)/sponsor": [~34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';